import React from 'react'
import BannerContent from "../../components/pagesContents/AboutPageContent/HomeBannerContent";
import Section1Content from "../../components/pagesContents/AboutPageContent/Section1Content";
import Section2Content from "../../components/pagesContents/AboutPageContent/Section2Content";
import Section3Content from "../../components/pagesContents/AboutPageContent/Section3Content";
<meta name="Découvrez Polytech School - Notre Mission, Vision et Valeurs" content="Découvrez l'histoire derrière Polytech School et notre engagement à promouvoir une communauté d'apprentissage dynamique pour les passionnés de mathématiques. Explorez notre mission, notre vision et nos valeurs alors que nous nous efforçons d'inspirer et d'habiliter les étudiants du monde entier." />

const AboutusPage = () => {
  return (
    <div className="flex justify-center flex-col overflow-hidden">
    {/* start of section */}
      <BannerContent />
    {/* end of section */}

    {/* start of section */}
      <Section1Content />
    {/* end of section */}

    {/* start of section */}
      <Section2Content />
    {/* end of section */}

    {/* start of section */}
      <Section3Content />
    {/* end of section */}

    </div>
  )
}

export default AboutusPage