import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Section1Content = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline();
    const boxs = document.querySelectorAll(".box");
    const cards = document.querySelectorAll(".card");

    cards.forEach((card) => {
      gsap.fromTo(
        card,
        {
          autoAlpha: 0,
          x: -100,
        },
        {
          duration: 0.6,
          autoAlpha: 1,
          x: 0,
          scrollTrigger: {
            trigger: card,
            start: "top 70%",
            end: "bottom 70%",
            scrub: 0.5,
          },
        }
      );
    });

    boxs.forEach((box, index) => {
      tl.fromTo(
        box,
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          duration: 0.6,
          autoAlpha: 1,
          y: 0,
          stagger: 0.1,
          scrollTrigger: {
            trigger: box,
            start: "top 75%",
            end: "bottom 75%",
            scrub: 0.5,
          },
        }
      );
    });
  }, []);

  return (
    <>
      <div className=" py-20 px-5 h-auto lg:h-[100vh] flex justify-center items-center">
        <div className=" text-center lg:text-left gap-8 lg:w-3/4 2xl:w-2/4 lg:mx-auto lg:px-0 lg:pt-0 justify-center items-start ">
          <div className="lg:grid gap-8 h-auto flex flex-col items-center justify-center mb-4">
            <div className=" card">
              <h2 className="text-4xl font-bold mb-4 text-[#3D67FF]">
                Pourquoi une Chaine ?
              </h2>
              <p className=" lg:text-left text-justify text-lg lg:text-xl rounded-xl ">
                Mon principal objectif est de vous accompagner de manière
                optimale dans votre quête de réussite en mathématiques, du
                secondaire aux études supérieures. Comme vous le savez, tout le
                monde n'a pas la chance de bénéficier d'un enseignement de
                qualité au secondaire, et certains programmes scolaires peuvent
                être incomplets voire inadaptés, rendant parfois difficile la
                transition vers l'enseignement supérieur, surtout pour ceux
                visant l'excellence dans des filières prestigieuses comme les
                études scientifiques ou les grandes écoles d'ingénieurs. C'est
                là que j'interviens, vous offrant l'opportunité d'apprendre à
                naviguer dans l'univers à la fois exigeant et fascinant des
                mathématiques. Pour atteindre cet objectif, voici les types de
                contenus qui devraient certainement vous intéresser.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="absolute left-0 -translate-y-20 -translate-x-10 max-md:hidden z-10">
          <img
            className="object-cover w-[80%]"
            src="./assets/image/illus1.png"
            alt="illustration"
          />
        </div>

        <div className=" absolute right-0 -translate-y-24 max-md:hidden z-10 justify-end flex">
          <img
            className="object-cover w-[80%]"
            src="./assets/image/illus4.png"
            alt="illustration"
          />
        </div>
      </div>
    </>
  );
};

export default Section1Content;
