import React, { useState } from "react";

const Section2Content = () => {
  const Gridlayout = [
    {
      Header: "Des Supports écrit pour accélérer Votre apprentissage",
      paragraph:
        "Découvrez nos exercices en ligne avec corrections et cours détaillés en mathématiques, du secondaire à l'université. Des ressources éducatives de qualité pour renforcer vos compétences mathématiques, adaptées à chaque niveau académique.",
      Image: "./assets/image/Service1.jpeg",
      styles:
        "box flex flex-col overflow-hidden h-fit rounded-[30px] gap-4 mb-4 lg:mb-0",
    },
    {
      Header: "Des vidéos YouTube sur mesures",
      paragraph:
        "Sur notre chaîne YouTube, Nous nous engageons à présenter les raisonnements mathématiques avec clarté et élégance, tout en offrant une expérience visuelle plaisante pour encourager les spectateurs à se plonger dans des concepts basiques et également complexes. notre énergie se nourrit de cette perspective enthousiasmante, avec une attention minutieuse aux détails, de l'écriture à la qualité des illustrations.",
      Image: "./assets/image/Service2.jpeg",
      styles:
        "box flex flex-col overflow-hidden h-fit rounded-[30px] gap-4 mb-4 lg:mb-0",
    },
    {
      Header: " Une communauté pour vous avec un suivi personnalisé ",
      paragraph:
        "En quelques mots, un serveur Discord se présente comme une plateforme de communication exceptionnellement efficace et polyvalente, principalement composée de salons textuels favorisant des échanges structurés et agréables. Vous pourriez également avoir accès à un suivi personnalisé et privé tout au long de votre apprentissage et les différents outils mis au point par notre équipe",
      Image: "./assets/image/Service3.jpeg",
      styles: "box flex flex-col overflow-hidden h-fit rounded-[30px] mb-4 lg:mb-0",
    },
  ];

  const [showFullText, setShowFullText] = useState(
    Array.from({ length: Gridlayout.length }).fill(false)
  );

  const handleToggleText = (index) => {
    const updatedShowFullText = [...showFullText];
    updatedShowFullText[index] = !updatedShowFullText[index];
    setShowFullText(updatedShowFullText);
  };

  return (
    <div className="bg-blue-100 relative">
      <div className="absolute left-0 -translate-y-20 -translate-x-5 max-md:hidden z-10">
        <img
          className="object-cover w-[80%] "
          src="./assets/image/colors2.png"
          alt="illustration"
        />
      </div>

      <div className="lg:w-3/4 2xl:w-2/4 lg:mx-auto py-20 px-5 ">
        <div className="h-auto lg:w-1/2 mb-20 text-center mx-auto">
          <h1 className="text-[15px] font-semibold text-[#1A6340] ">Service</h1>
          <h2 className="text-lg lg:text-4xl font-bold mt-4 text-[#3D67FF]">
            Nos Services Personnalisés
          </h2>
        </div>

        <div className="lg:grid lg:grid-cols-3 lg:grid-row-2 gap-5 ">
          {Gridlayout.map((card, index) => (
            <div key={index} className={card.styles}>
              <div className="w-full h-[200px] rounded-md overflow-hidden">
                <img
                  className="object-cover w-full"
                  src={card.Image}
                  alt="grid"
                />
              </div>

              <div className="px-5 pt-2 pb-5 bg-indigo-500">
                <h1 className="text-lg text-white font-extrabold mb-2">
                  {card.Header}
                </h1>
                <p
                  className={`text-[#EFF2FB] transition-all duration-200 ease-linear ${
                    showFullText[index] ? "" : "line-clamp-2"
                  }`}
                >
                  {card.paragraph}
                </p>
                <p
                  className="text-blue-200 text-sm underline cursor-pointer"
                  onClick={() => handleToggleText(index)}
                >
                  {showFullText[index] ? "Lire moins" : "Lire plus"}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className=" absolute right-0 -translate-y-2  max-md:hidden z-10 flex justify-end">
          <img
            className="object-cover w-[80%]"
            src="./assets/image/colors1.png"
            alt="illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default Section2Content;
