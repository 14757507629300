import React from "react";
// import Button from "../../common/Button";

const YoutubeBanner = () => {
  return (
    <div className="youtube-Banner gap-4 px-5 py-10 lg:py-0 lg:pt-0 md:px-10 h-[100vh] flex flex-col lg:items-center items-start justify-center  overflow-hidden ">
      <div className="items-center justify-between mx-auto text-center lg:p-10 flex flex-col mb-20 lg:mb-0">
        <div className="mt-20 lg:mt-0 m-auto">
          <h1 className="font-sans font-bold text-4xl lg:text-3xl text-white text-center lg:w-2/3 mx-auto  mb-8 leading-relaxed">
            Découvrez notre bibliothèque de vidéos sur YouTube pour exceller en
            maths et réussir vos examens d'admission!
          </h1>
        </div>
      </div>

      <div className="arrow w-fit mx-auto rounded-full bg-blue-400 justify-center flex flex-wrap gap-3 flex-row pt-10">
        <img src="./assets/image/arrow-down.svg" alt="arrow" />
      </div>
    </div>
  );
};

export default YoutubeBanner;
