import React from "react";
import BannerContent from "../../components/pagesContents/HomePageContent/HomeBannerContent";
import Section1Content from "../../components/pagesContents/HomePageContent/Section1Content";
import Section5Content from "../../components/pagesContents/HomePageContent/Section5Content";
import GridLayout from "../../components/common/GridLayout";
<meta name="Polytech School - Encourageant les passionnés de mathématiques" content="Bienvenue à Polytech School, où les étudiants passionnés explorent le monde de l'éducation mathématique et de l'échange d'idées. Rejoignez notre communauté d'apprenants alors que nous explorons ensemble la beauté et la complexité des mathématiques." />

const HomePage = () => {
  return (
    <div className="flex justify-center flex-col overflow-hidden">

<section className="Home-Banner py-2 px-5  flex flex-col items-center justify-center h-[100vh] ">
      {/* start of section */}
      <BannerContent />
      {/* end of section */}
  </section>

      {/* start of section */}
        <Section1Content />
      {/* end of section */}

        
      {/* start of section */}
        <GridLayout />
      {/* end of section */}


      {/* start of section */}
      <Section5Content />
      {/* end of section */}

    </div>
  );
};

export default HomePage;
