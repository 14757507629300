import React from "react";
// import Button from "../../common/Button";

const BannerContent = () => {
  return (
    <div className="about-banner gap-4 px-5 py-5 lg:py-0 lg:pt-0 md:px-10 h-[100vh] flex flex-col lg:items-center justify-center">
      <div className="items-center mx-auto text-center lg:p-10 flex justify-center flex-col my-auto h-[100%]">
        <div className="mb-20 lg:mb-0">
          <h1 className="font-sans font-bold text-4xl lg:text-3xl text-white lg:leading-20 mb-8">
            Qui suis-je ?
          </h1>
        </div>

        <div className="arrow mt-4 w-fit mx-auto rounded-full bg-blue-400 justify-center flex flex-wrap gap-3 flex-row pt-10 ">
          <img src="./assets/image/arrow-down.svg" alt="arrow" />
        </div>
      </div>
    </div>
  );
};

export default BannerContent;
