import React from "react";
import Button from "../../common/Button";

const Section3Content = () => {
  return (
    <div className="lg:w-3/4 2xl:w-2/4 lg:mx-auto py-20 px-5 ">
      <div className="h-auto lg:w-1/2 text-center mx-auto">
        <h1 className="text-[15px] font-semibold text-[#1A6340] ">
          Cours Particuliers
        </h1>
        <h2 className=" text-lg lg:text-4xl font-bold mt-4 text-[#3D67FF]">
          Prendrez un rendez-vous Gratuit maintenant
        </h2>
      </div>

      {/* Grid layout section */}
      <div className="justify-center items-center lg:grid lg:grid-cols-2 max-sm:hidden md:hidden">
        <div className="col-span-1 box">
          <div className=" w-[350px]">
            <div className="flex justify-center lg:justify-end w-full py-10 max-sm:hidden relative items-center">
              <img
                className="illus object-cover rounded-2xl w-[400px] z-10"
                src="./assets/image/school.png"
                alt="education"
              />
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <h1>
            Découvrez nos appels gratuits 'Better Call Dyrbil' pour un soutien
            personnalisé ! Profitez de sessions de consultation d'une heures en
            ligne via Google Meet pour obtenir des réponses à vos questions, un
            court coaching adapté et de l'aide sur mesure pour exceller dans vos
            études de mathématiques. Réservez votre créneau dès maintenant{" "}
          </h1>
          <div className="button mt-6 flex flex-wrap gap-3 flex-row max-lg:hidden">
            <Button
              // eslint-disable-next-line
              label={"Prendre Rendez-vous"}
              customLink={
                "https://calendly.com/polytech-school/polytechschool"
              }
              customStyle={
                // eslint-disable-next-line
                "w-full lg:w-fit text-sm bg-indigo-600 text-white font-bold px-5 py-2 rounded-[30px] "
              }
            />
          </div>
        </div>
      </div>

      <div className="button mt-6 flex flex-wrap gap-3 flex-row lg:hidden ">
        <p className="text-center my-4 p-5 bg-white text-blue-800 font-bold rounded-xl">
          Chez nous, l'étudiant est au cœur de notre approche éducative. Nous
          comprenons que chaque élève est unique, avec ses propres forces et
          défis. Voici comment notre approche bienveillante fait toute la
          différence.
        </p>

        <Button
          label={"Prendre Rendez-vous"}
          customLink={
            "https://calendly.com/polytech-school/polytechschool"
          }
          customStyle={
            "w-full text-center bg-blue-500 lg:w-fit text-sm text-white border-2 font-bold px-[32px] py-[16px] rounded-[30px] "
          }
        />
      </div>
    </div>
  );
};

export default Section3Content;
