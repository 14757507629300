import React from "react";

const GridLayout = () => {
  let Gridlayout = [
    {
      Header: "Des vidéos YouTube de qualité",
      paragraph:
        "Retrouvez des vidéos YouTube simples, claires et adaptées à tous les niveaux, du secondaire à l'université. Préparez-vous efficacement pour vos examens, y compris les admissions à des épreuves telles que l'examen d'entrée à l'École Polytechnique de Bruxelles et bien d'autres.",
      Image: "./assets/image/Exams.png",
      styles:
        "box mb-4 lg:mb-0 bg-pink-600 flex lg:p-8 px-5 py-8 items-center justify-center rounded-[30px] gap-4",
    },
    {
      Header: "Des cours et des exercices a votre disposition",
      paragraph:
        "Explorez nos cours de mathématiques, comprenant des exercices et des solutions rédigés par notre équipe, disponibles au format écrit (PDF). Ces cours sont le fruit des expériences variées accumulées au cours de nos parcours académiques et professionnels, notamment dans le domaine des cours particuliers et de la préparation aux examens",
      Image: "./assets/image/Typing.png",
      styles:
        "box mb-4 lg:mb-0 bg-[#A555B8] flex lg:p-8 px-5 py-8 items-center justify-center rounded-[30px] gap-4",
    },
    {
      Header: "Une communautés Discord engagé",
      paragraph:
        "Avoir une communauté sur discord nous permets d’être plus connectes ensemble et de profiter des différents avantages comme l’écriture des équations mathématiques simple, forum Question-Reponse, des séances de travail en ligne, des annonces et pliens d’autres fonctionnalités que je vous laisse découvrir.",
      Image: "./assets/image/Time.png",
      styles:
        "box mb-4 lg:mb-0 bg-[#735FF2] flex lg:p-8 px-5 py-8 items-center justify-center rounded-[30px] gap-4",
    },
    {
      Header: "Coaching personnalisé et Cours particuliers",
      paragraph:
        "Bénéficiez d'un accompagnement d’étudiants qualifiés et bienveillant tout au long de vos études pour vous donner les meilleures chances de réaliser vos objectifs. Au besoin nous réalisons sur commande des vidéos d'enseignement en mathématiques de qualité professionnelle, adaptées à chaque niveau d'apprentissage",
      Image: "./assets/image/studying.png",
      styles:
        "box mb-4 lg:mb-0 bg-[#5DB55F] flex lg:p-8 px-5 py-8 items-center justify-center rounded-[30px] gap-4",
    },
  ];

  return (
    <div className="lg:w-3/4 2xl:w-2/4 lg:mx-auto py-20 px-5">
      <div className="h-auto lg:w-2/3 mx-auto text-center justify-center pb-20">
        <h2 className="text-lg lg:text-4xl font-bold mt-4 text-[#3D67FF]">
          {" "}
          Quelle dispositifs <br /> innovants offrons nous ?
        </h2>
      </div>

      {/* Grid layout section */}
      <div className="lg:grid lg:grid-cols-2 lg:grid-row-2 gap-5">
        {Gridlayout.map((card, index) => (
          <div key={index} className={card.styles}>
            {/* <div className="w-[300px] h-auto max-sm:hidden">
              <img className="object-cover" src={card.Image} alt="grid" />
            </div> */}

            <div className="">
              <h1 className="text-xl text-white font-extrabold mb-4">
                {card.Header}
              </h1>
              <p className=" text-[#EFF2FB] text-left"> {card.paragraph} </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GridLayout;
