import React from "react";

const Section3Content = () => {
  return (
    <div className="bg-blue-100 py-20 px-5 justify-center items-center">
      <div className="h-auto lg:w-2/3 mx-auto text-center pb-20">
        <h2 className="text-lg lg:text-4xl font-bold mt-4 text-[#3D67FF]">
          Nos Astuces <br />
          mathématiques
        </h2>
      </div>

      {/* Card 1 */}
      <div className="card lg:w-3/4 2xl:w-2/4 mx-auto mb-10 rounded-lg lg:grid lg:grid-cols-2 justify-center bg-white p-10 items-center my-auto">
        <div className="col-span-1 text-left justify-center">
          <h2 className="lg:text-3xl mb-4 lg:text-left text-xl font-bold text-[#3D67FF] leading-relaxed">
            Cours et exercices de mathématiques niveau secondaire vers
            l'université
          </h2>
        </div>
        <div className="col-span-1">
          <p>
            Explorez nos ressources complètes et bénéficiez d'un enseignement de
            qualité pour réussir dans le domaine des mathématiques, quel que
            soit votre niveau.
          </p>
          <h3 className="mt-4">
            <a
              className="underline text-blue-200 hover:text-blue-400 "
              href="https://drive.google.com/drive/folders/1E3-95Ad7973tQjrj1_HFRggXYcIx4hJU?usp=drive_link"
            >
              Liens vers les dispositifs
            </a>
          </h3>
        </div>
      </div>

      {/* Card 2 */}
      <div className="card lg:w-3/4 2xl:w-2/4 mx-auto mb-10 rounded-lg lg:grid lg:grid-cols-2 justify-center bg-white p-10 items-center my-auto">
        <div className="col-span-1 text-left justify-center">
          <h2 className="lg:text-3xl mb-4 lg:text-left text-xl font-bold text-[#3D67FF] leading-relaxed">
            Cours et exercices <br /> Préparation à L'examen d'admission à
            L'École Polytechnique de Bruxelles
          </h2>
        </div>
        <div className="col-span-1">
          <p>
            Préparez-vous efficacement à l'examen d'admission à l'École
            Polytechnique de Bruxelles avec nos cours et exercices spécialisés.
          </p>
          <h3 className="mt-4">
            <a
              className="underline text-blue-200 hover:text-blue-400 "
              href="https://drive.google.com/drive/folders/1ncsmvmBxs1XIyV6Mi5SuTChOEKJqwTyZ?usp=drive_link"
            >
              Liens vers les dispositifs
            </a>
          </h3>
        </div>
      </div>

      {/* Card 3 */}
      <div className="card lg:w-3/4 2xl:w-2/4 mx-auto mb-10 rounded-lg lg:grid lg:grid-cols-2 justify-center bg-white p-10 items-center my-auto">
        <div className="col-span-1 text-left justify-center">
          <h2 className="lg:text-3xl mb-4 lg:text-left text-xl font-bold text-[#3D67FF] leading-relaxed">
            Exercices,tests et <br /> Quiz en ligne
          </h2>
        </div>
        <div className="col-span-1">
          <p>
            Testez-vous, relevez des défis et mesurez votre progrès grâce à nos
            exercices et quiz en ligne.
          </p>
          <h3 className="mt-4">
            <a
              className="underline text-blue-200 hover:text-blue-400 "
              href="https://www.geogebra.org/classroom/e3r2yqbj"
            >
              Liens vers les dispositifs
            </a>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Section3Content;
