import React from "react";
import Button from "../../common/Button";

const BannerContent = () => {
  return (
    <>
      <div className=" lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:w-3/4 2xl:w-2/4 lg:mx-auto justify-between items-center mb-20 lg:mb-0 ">
        <div className="flex flex-col justify-between lg:mr-10">
          <div className=" text-center lg:text-left">
            <h2 className="lg:text-5xl text-4xl font-bold text-[#ffffff]">
              Optimisez votre apprentissage des{" "}
              <span className="gradient-text-banner">mathématiques</span>
            </h2>
            <p className="mt-4 text-lg text-white">
              Nous sommes une communauté engagée pour vous accompagner tout au
              long de votre apprentissage du secondaire à l’université et à la
              préparation aux examens d’admission pour des école d’ingénieur
              comme L’Ecole Polytechnique de Bruxelles et bien d’autres.
            </p>
          </div>
          <div className="button mt-6 flex flex-wrap gap-3 flex-row max-sm:hidden ">
            <Button
              label={"réservez un cours gratuit"}
              customLink={
                "https://calendly.com/polytech-school/polytechschool"
              }
              customStyle=// eslint-disable-next-line
              "bg-white w-full lg:w-fit text-indigo-900 font-bold px-5 py-2 rounded-[30px] "
            />
          </div>
        </div>

        <div className="flex mt-10 justify-center lg:justify-end w-full py-10 relative items-center">
          <div className="bg-green-200 circle rounded-full w-1/2 h-[50%] lg:w-2/3 lg:h-[80%] absolute shadow-2xl shadow-white"></div>
          <div className="bg-white circle rounded-full w-1/2 h-1/2 lg:w-2/3 lg:h-2/3 absolute shadow-2xl shadow-green-200"></div>
          <img
            className="illus object-cover rounded-2xl w-1/2 lg:w-[400px] z-10 "
            src="./assets/image/home-logo.png"
            alt="education"
          />
        </div>
      </div>

      <div className=" home-Btn-anim mt-6 flex flex-wrap gap-3 flex-row lg:hidden">
        <Button
          label={"réservez un cours gratuit"}
          customLink={
            "https://calendly.com/polytech-school/polytechschool"
          }
          customStyle=// eslint-disable-next-line
          "bg-white w-full lg:w-fit text-indigo-900 font-bold px-8 py-4 rounded-[30px] "
        />
      </div>

      <div className="arrow max-sm:hidden w-fit mx-auto rounded-full bg-blue-400 justify-center flex flex-wrap gap-3 flex-row pt-10">
        <img src="./assets/image/arrow-down.svg" alt="arrow" />
      </div>
    </>
  );
};

export default BannerContent;
