import React, { useState } from "react";

const Section2Content = () => {
  let Gridlayout = [
    {
      Header: "Trouvez des groupes d'étude qui répondent à vos besoins",
      paragraph:
        "Parcourez un large éventail de groupes d'étude couvrant divers sujets et sujets. Connectez-vous avec des étudiants partageant les mêmes idées et améliorez vos performances académiques.",
      Image: "./assets/image/Rec1.jpeg",
      styles:
        "box flex flex-col bg-white lg:bg-transparent  h-fit gap-4 mb-8 lg:mb-0",
    },
    {
      Header: "Créez votre propre groupe d'étude",
      paragraph:
        "Prenez les devants et formez des groupes d'étude en fonction de vos intérêts et de vos besoins. Partagez vos connaissances, collaborez avec vos pairs et excellez dans vos études.",
      Image: "./assets/image/Rec2.jpeg",
      styles:
        "box flex flex-col bg-white lg:bg-transparent  h-fit gap-4 mb-8 lg:mb-0",
    },
    {
      Header: "Restez organisé et motivé dans vos études",
      paragraph:
        "Utilisez les fonctionnalités de Discord pour rester organisé et productif dans vos groupes d'étude. Fixez-vous des objectifs, suivez les progrès et motivez-vous mutuellement vers la réussite scolaire.",
      Image: "./assets/image/Rec3.jpeg",
      styles:
        "box flex flex-col bg-white lg:bg-transparent h-fit gap-4 mb-8 lg:mb-0",
    },
  ];

  const [showFullText, setShowFullText] = useState(
    Array.from({ length: Gridlayout.length }).fill(false)
  );

  const handleToggleText = (index) => {
    const updatedShowFullText = [...showFullText];
    updatedShowFullText[index] = !updatedShowFullText[index];
    setShowFullText(updatedShowFullText);
  };

  return (
    <div className="bg-blue-100 relative ">
      <div className="lg:w-3/4 2xl:w-2/4 lg:mx-auto py-20 px-5 ">
        <div className="h-auto lg:w-1/2 pb-10 lg:pb-20 text-center mx-auto">
          <h1 className="text-[15px] font-semibold text-[#1A6340] ">
            Libérez votre potentiel
          </h1>
          <h2 className="text-lg lg:text-4xl font-bold mt-4 text-[#3D67FF]">
            Découvrez et rejoignez nous sur Discord
          </h2>
        </div>

        {/* Grid layout section */}
        <div className=" lg:grid lg:grid-cols-3 lg:grid-row-2 gap-10 ">
          {Gridlayout.map((card, index) => (
            <div key={index} className={card.styles}>
              <div className="w-full h-[200px] rounded-md overflow-hidden ">
                <img
                  className="object-cover w-full"
                  src={card.Image}
                  alt="grid"
                />
              </div>

              <div className="p-5 lg:p-0">
                <h1 className="text-xl text-indigo-400 font-extrabold mb-4">
                  {" "}
                  {card.Header}{" "}
                </h1>
                <p
                  className={`text-[#000000] transition-all duration-200 ease-linear ${
                    showFullText[index] ? "" : "line-clamp-2"
                  }`}
                >
                  {card.paragraph}
                </p>
                <p
                  className="text-blue-400 text-sm underline cursor-pointer"
                  onClick={() => handleToggleText(index)}
                >
                  {showFullText[index] ? "Lire moins" : "Lire plus"}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <img
        className="mx-auto w-3/4 2xl:w-2/4"
        src="./assets/image/Line.png"
        alt=""
        srcset=""
      />
    </div>
  );
};

export default Section2Content;
