import React from "react";
import Button from "../../common/Button";

const Section2Content = () => {
  let Gridlayout = [
    {
      Header: "Résolution d'une inéquation",
      Lien: "https://youtu.be/FSepCsNwLTc?si=qrnAyusYD-VlX0nO",
      paragraph:
        "Des explications de cours limpides, dans lesquelles je présente simplement des notions mathématiques simple.",
      Image: "./assets/image/thumbnail1.jpg",
      styles:
        "box flex flex-col rounded-lg h-fit lg:grid lg:grid-cols-2 gap-8 items-start overflow-hidden mb-8 lg:mb-0 ",
    },
    {
      Header: "Calcule la hauteur de la Tour",
      Lien: "https://youtu.be/EHC5DcXoSjM?si=E893jr47I4y4Xm5B",
      paragraph:
        "Des astuces ingénieuses pour resoudre des problèmes pratiques à l’aide des mathématiques.",
      Image: "./assets/image/thumbnail2.jpg",
      styles:
        "box flex flex-col rounded-lg h-fit lg:grid lg:grid-cols-2 gap-8 items-start overflow-hidden mb-8 lg:mb-0 ",
    },
    {
      Header: "Calcule d'intégrale",
      Lien: "https://youtu.be/xCJSHfkks24?si=VpZD3fN0_xmDcO_z",
      paragraph:
        "Des démonstrations de théorèmes de propositions emblématiques des mathématiques et des astuces de calcule innovante",
      Image: "./assets/image/thumbnail3.jpg",
      styles:
        "box flex flex-col rounded-lg h-fit lg:grid lg:grid-cols-2 gap-8 items-start overflow-hidden mb-8 lg:mb-0 ",
    },
  ];

  return (
    <div className="bg-indigo-100 relative ">
      <div className="lg:w-3/4 2xl:w-2/4 lg:mx-auto py-20 px-5 ">
        <div className="lg:bg-white rounded-xl lg:p-10">
          <div className="lg:grid lg:grid-cols-2 justify-between rounded-xl mb-10 lg:p-10 p-5 bg-gray-100">
            <div className="box lg:col-span-1 max-sm:hidden">
              <img
                className=""
                src="./assets/image/youtube-illus.png"
                alt="arrow"
              />
            </div>

            <div className="col-span-1 card">
              <h2 className="text-lg lg:text-4xl font-bold text-[#3D67FF] ">
                La chaîne de référence pour vous améliorer en mathématiques
              </h2>
              <div className="mt-4">
                <p className="text-justify lg:text-left">
                  certains programmes scolaires peuvent être incomplets voire
                  inadaptés, rendant parfois difficile la transition vers
                  l'enseignement supérieur, surtout pour ceux visant
                  l'excellence dans des filières prestigieuses comme les études
                  scientifiques ou les grandes écoles d'ingénieurs.
                </p>
              </div>
            </div>
          </div>

          {/* Grid layout section */}
          <div className=" lg:grid lg:grid-row-2 gap-10 ">
            {Gridlayout.map((card, index) => (
              <a href={card.Lien} key={index} className={card.styles}>
                <div className="w-full col-span-1">
                  <img
                    className="object-cover rounded-2xl"
                    src={card.Image}
                    alt="grid"
                  />
                </div>

                <div className=" pb-5 pt-2 col-span-1 align-middle flex flex-col gap-5">
                  <h1 className=" text-lg lg:text-2xl text-[#548aff] font-extrabold mb-2">
                    {card.Header}
                  </h1>
                  <p className=" eadind-relaxed"> {card.paragraph} </p>
                  <h1 className="w-full lg:w-fit text-sm bg-indigo-600 max-sm:text-center text-white font-bold px-5 py-2 rounded-[30px] ">
                    voir la video
                  </h1>
                </div>
              </a>
            ))}
          </div>

          <div className="mt-10 justify-center flex">
            <Button
              // eslint-disable-next-line
              label={"voir plus"}
              customLink={
                "https://youtube.com/@PolytechSchool?si=Btg5-OzG5AcQQkL_"
              }
              customStyle={
                // eslint-disable-next-line
                "w-full lg:w-fit text-lg max-sm:text-center border-2 text-indigo-600 border-2 font-bold px-5 py-2 rounded-[30px] "
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2Content;
