// src/components/common/Button.js
import React from "react";

const Button = ({ label, customLink, customStyle }) => {
  return (
    <a href={customLink} className={customStyle + 'btn-animation'}>
      {label}
    </a>
  );
};

export default Button;
