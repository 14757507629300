import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Section1Content = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline();
    const boxs = document.querySelectorAll(".box");
    const cards = document.querySelectorAll(".card");

    cards.forEach((card) => {
      gsap.fromTo(
        card,
        {
          autoAlpha: 0,
          x: -100,
        },
        {
          duration: 0.6,
          autoAlpha: 1,
          x: 0,
          scrollTrigger: {
            trigger: card,
            start: "top 70%",
            end: "bottom 70%",
            scrub: 0.5,
          },
        }
      );
    });

    boxs.forEach((box, index) => {
      tl.fromTo(
        box,
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          duration: 0.6,
          autoAlpha: 1,
          y: 0,
          stagger: 0.1,
          scrollTrigger: {
            trigger: box,
            start: "top 70%",
            end: "bottom 70%",
            scrub: 0.5,
          },
        }
      );
    });
  }, []);
  return (
    <>
      <div className="lg:w-3/4 2xl:w-2/4 mx-auto">
        <div className="text-center lg:text-left lg:grid lg:grid-cols-5 lg:grid-flow-row  px-5 lg:h-[100vh] pt-20 lg:pt-0 justify-center items-center ">
          <div className="lg:pr-10 col-span-3 card">
            <h1 className="text-indigo-400 font-extrabold text-xl text-left lg:text-4xl mb-8 ">
              {" "}
              les mathématiques plus captivantes et vivantes
            </h1>
            <p className=" text-justify lg:text-left text-lg">
              Il est primordial de s'unir pour partager nos idées et libérer
              notre véritable potentiel. De par mon expérience, travailler en
              groupe favorise grandement l'échange de connaissances, que ce soit
              en posant des questions ou en y répondant, c’est l’une des choses
              qui m’a énormément aidé durant mon apprentissage et fera de méme
              pour toi.
            </p>
          </div>

          <div className="flex justify-center w-full py-10 col-span-2 box">
            <img
              className="object-cover"
              src="./assets/image/maths-illus.png"
              alt="education"
            />
          </div>
        </div>

        <img
          className="mx-auto "
          src="./assets/image/Line.png"
          alt=""
          srcset=""
        />
      </div>

      <div className="overflow-hidden">
        <div className="absolute left-0 -translate-y-20 -translate-x-0 max-md:hidden z-10">
          <img
            className="object-cover w-[80%]"
            src="./assets/image/equation.png"
            alt="illustration"
          />
        </div>
      </div>
    </>
  );
};

export default Section1Content;
