import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Navbar = () => {
  let navLinks = [
    {
      linkName: "Accueil",
      linkUrl: "/",
    },
    {
      linkName: "Discord",
      linkUrl: "/discord",
    },
    {
      linkName: "À Propos",
      linkUrl: "/a-propos",
    },
    {
      linkName: "Maths",
      linkUrl: "/maths",
    },
    {
      linkName: "Youtube",
      linkUrl: "/youtube",
    },
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const location = useLocation();

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const scrollThreshold = 10; // Adjust this value based on your needs

    setScrolling(scrollPosition > scrollThreshold);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div
      className={` bg-${
        scrolling ? "white" : "transparent"
      } duration-80 ease-in-out text-${
        scrolling ? "black" : "white"
      } lg:drop-shadow-xl z-50 flex w-full fixed justify-between px-5 lg:px-20 py-5 lg:py-5 items-center 2xl:px-[25%]`}
    >
      <a className="w-[30%] lg:w-[15vw] 2xl:w-[10vw] " href="/">
        <img
          className="object-cover"
          src={
            scrolling
              ? "./assets/image/logo.png"
              : "./assets/image/white_logo.png"
          }
          alt="polytech school"
        />
      </a>

      {/* Desktop Navigation Links */}
      <ul className="flex gap-3 max-sm:hidden">
        {navLinks.map((link, index) => (
          <li key={index} className="p-2 text-md ">
            <NavLink
              to={link.linkUrl}
              className={
                location.pathname === link.linkUrl
                  ? "lg:border-b-4 border-red-500 pb-2 font-bold"
                  : "text-normal "
              }
            >
              {link.linkName}
            </NavLink>
          </li>
        ))}
      </ul>

      {/* Mobile Icon */}
      <div
        className="text-white text-sm not-italic leading-[normal] whitespace-nowrap justify-center items-stretch  flex flex-col px-2 py-1.5 md:hidden cursor-pointer"
        onClick={toggleMobileMenu}
      >

<img
          className="object-contain"
          src={
            scrolling
              ? "./assets/image/menu-indigo.svg"
              : "./assets/image/menu-white.svg"
          }
          alt="menu"
        />
      </div>

      {/* Mobile Navigation Menu */}
      {mobileMenuOpen && (
        <div className="fixed flex-col top-0 left-0 w-full h-[100vh] bg-indigo-500 text-white flex items-center justify-center ">
          <div
            className="text-white text-sm not-italic leading-[normal] whitespace-nowrap justify-center items-stretch flex flex-col px-2 py-1.5 md:hidden cursor-pointer mb-10"
            onClick={toggleMobileMenu}
          >
            <img src="./assets/image/x.svg" alt="exit" />
          </div>

          <ul className="text-center">
            {navLinks.map((link, index) => (
              <li key={index} className="py-4">
                <NavLink
                  to={link.linkUrl}
                  onClick={toggleMobileMenu}
                  className="text-white text-xl font-semibold"
                >
                  {link.linkName}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Navbar;
