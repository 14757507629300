import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Section5Content = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const counters = document.querySelectorAll(".counter");

    counters.forEach((counter, index) => {
      const roundedValue = parseInt(
        Math.floor(counter.getAttribute("data-target-value"))
      );
      ScrollTrigger.create({
        trigger: counter,
        start: "top 80%",
        onEnter: () => {
          gsap.to(counter, {
            duration: 1,
            innerText: roundedValue,
            ease: "power1.inOut",
          });
        },
      });
    });
  }, []);

  return (
    <div className="bg-blue-100 py-20 px-5">
      <div className="lg:w-3/4 2xl:w-2/4 mx-auto gap-8 justify-center items-center">
        <div className="col-span-1 text-left justify-center box">
          <h2 className="lg:text-4xl text-center text-2xl font-bold text-[#3D67FF] leading-relaxed">
            Relevez le défi <span className="bg-red-100">mathématique</span>,{" "}
            <br />
            <span className="bg-white">Explorez</span>, apprenez et grandissez{" "}
            <br /> avec notre <span className="bg-green-200">communauté</span>
          </h2>
        </div>
        <div className="lg:flex justify-center lg:justify-between mt-20">
          {/* box1 */}
          <div className="flex mb-8 lg:mb-0 flex-col justify-center items-center overflow-hidden box">
            <div className="flex text-7xl font-bold gradient-text">
              +
              <span className="counter" data-target-value="1000">
                0
              </span>
            </div>
            <label className="text-white text-xl pt-5 font-semibold">
              Abonnés
            </label>
          </div>

          {/* box2 */}
          <div className="flex mb-8 lg:mb-0 flex-col justify-center items-center overflow-hidden box">
            <div className="flex text-7xl font-bold gradient-text">
              +
              <span className="counter" data-target-value="200">
                0
              </span>
            </div>
            <label className="text-white text-xl pt-5 font-semibold">
              Vidéos
            </label>
          </div>

          {/* box3 */}
          <div className="flex  flex-col justify-center items-center overflow-hidden  box">
            <div className="flex text-7xl font-bold gradient-text ">
              +
              <span className="counter " data-target-value="300">
                0
              </span>
            </div>
            <label className="text-white text-xl pt-5 font-semibold">
              Exercices
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5Content;
