import React from "react";
import BannerContent from "../../components/pagesContents/MathPageContent/MathBannerContent";
import Section1Content from "../../components/pagesContents/MathPageContent/Section1Content";
import Section2Content from "../../components/pagesContents/MathPageContent/Section2Content";
import Section3Content from "../../components/pagesContents/MathPageContent/Section3Content";
<meta name="Articles Mathématiques Instructifs sur le Blog de Polytech School" content="Plongez dans notre collection d'articles et de ressources stimulants sur l'éducation mathématique et l'échange d'idées. Explorez des perspectives diverses, engagez-vous avec des idées d'experts et restez à jour avec les dernières tendances dans le monde des mathématiques." />

const HomePage = () => {
  return (
    <div className="flex justify-center flex-col overflow-hidden">
      {/* start of section */}
        <BannerContent />
      {/* end of section */}

      {/* start of section */}
        <Section1Content />
      {/* end of section */}


      {/* start of section */}
        <Section2Content />
      {/* end of section */}


      {/* start of section */}
      <Section3Content />
      {/* end of section */}

    </div>
  );
};

export default HomePage;
