import React from "react";
import Button from "../../common/Button";

const Section3Content = () => {
  return (
    <div className="bg-blue-100 py-20 px-5 lg:h-[70vh] overflow-hidden flex justify-center items-center -z-20">
      <div className="lg:w-3/4 lg:grid lg:grid-cols-2 2xl:w-2/4 lg:mx-auto justify-center items-center  relative">
        <div className="h-auto text-center lg:text-left col-span-1 card">
          <h2 className="text-lg lg:text-4xl font-bold mb-4 text-[#898dc9]">
            Connectez-vous avec des étudiants partageant les mêmes idées que
            vous
          </h2>
          <h1 className="text-[#000000] eadind-relaxed ">
            Rejoignez une communauté dynamique d'étudiants et élargissez vos
            horizons d'apprentissage. Avec notre plateforme, se connecter avec
            des groupes d'étude n'a jamais été aussi simple.
          </h1>
          <div className=" mt-6 flex flex-wrap gap-3 flex-row">
            <Button
              // eslint-disable-next-line
              label={"Rejoignez"}
              customLink={"https://discord.gg/dTduDmScqN"}
              customStyle=// eslint-disable-next-line
              "bg-blue-500 w-full lg:w-fit text-white font-bold px-5 py-2 rounded-[30px] "
            />
          </div>
        </div>

        {/* Grid layout section */}
        <div className="lg:flex justify-center col-span-1 hidden max-md:visible box ">
          <div className=" absolute translate-x-[20%] -translate-y-[30%] -z-0 w-[70vw]">
            <img
              className=""
              src="./assets/image/discord-logo.png"
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3Content;
