import React, { useState } from 'react';
import axios from 'axios';


const Footer = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send form data to Formspree
      await axios.post('https://formspree.io/f/xleqjrgv', formData);

      // Log success or redirect the user
      console.log('Form submitted successfully!');
      // You can redirect the user or show a success message as needed

    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error, show error message, or redirect the user
    }

    // Reset the form after submission
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <div className=" h-auto text-left ">
      <img
        className="mx-auto w-3/4 2xl:w-2/4"
        src="./assets/image/Line.png"
        alt=""
        srcset=""
      />

      <div className=" lg:grid lg:grid-cols-5 lg:w-3/4 2xl:w-2/4 mx-auto px-5 py-10 lg:py-[100px] items-center">
        <div className=" col-span-3 card">
          <div className="h-auto lg:w-1/2 pb-10">
            <h2 className="lg:text-4xl font-bold mt-4 text-[#3D67FF]">
              Contact Information
            </h2>
            <p className="mt-4">
            Avez-vous des questions ou avez-vous besoin de support ? <br />Contactez-nous.
            </p>
          </div>

<div className="flex gap-4 items-center justify-start">
  <img className="bg-blue-100 rounded-full p-2" src="./assets/image/mail.svg" alt="mail" />
  <h1 className="text-blue-800">dybrilnahoussi@polytechschool.com</h1>
</div>

        </div>



        <div className="col-span-2 mt-8 lg:mt-0 box">
          <form onSubmit={handleSubmit} netlify>
            <div className="mb-4">
              <input
              placeholder='Noms'
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="mt-1 p-2 border rounded-md w-full"
              />
            </div>

            <div className="mb-4">
              <input
              placeholder='Email'
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-1 p-2 border rounded-md w-full"
              />
            </div>

            <div className="mb-4">
              <textarea
              placeholder='Message'
                cols="50"
                rows="3"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                className="mt-1 p-2 border rounded-md w-full"
              ></textarea>
            </div>

            <button
              type="submit"
              className="bg-blue-500 w-full text-white font-bold px-5 py-2 rounded-[30px] hover:bg-blue-600"
            >
            Envoyer
            </button>
          </form>
        </div>
      </div>
      <div className="bg-[#102770] h-auto p-10">
        <div className="lg:w-3/4 2xl:w-2/4 mx-auto">
          <div className="border-b-4 border-white ">
            <img
              className="w-40 object-cover py-5"
              src="./assets/image/white_logo.png"
              alt=""
              srcset=""
            />
          </div>
          <div className="items-start py-5 text-white font-bold">
            © 2024 Polytechschool. All rights reserved
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
