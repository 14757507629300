import React from "react";

const GridLayout = () => {
  let Gridlayout = [
    {
      Header: "Stratégies d'Organisation",
      paragraph:
        "Apprenez des techniques efficaces pour organiser votre temps d'étude, gérer les tâches et établir des priorités afin d'optimiser votre productivité académique",
      styles:
        "box mb-4 lg:mb-0 bg-blue-400 flex lg:p-8 px-5 py-8 items-center justify-center rounded-[30px] gap-4",
    },
    {
      Header: "Techniques de Mémorisation",
      paragraph:
        "Découvrez des méthodes de mémorisation éprouvées pour retenir facilement et efficacement les informations importantes, qu'il s'agisse de formules mathématiques, de concepts clés ou de données spécifiques",
      styles:
        "box mb-4 lg:mb-0 bg-blue-500 flex lg:p-8 px-5 py-8 items-center justify-center rounded-[30px] gap-4",
    },
    {
      Header: "Approches de Résolution de Problèmes",
      paragraph:
        "Explorez des approches systématiques pour résoudre des problèmes mathématiques complexes, en développant votre capacité à analyser, conceptualiser et appliquer des stratégies de résolution adaptées.",
      styles:
        "box mb-4 lg:mb-0 bg-blue-500 flex lg:p-8 px-5 py-8 items-center justify-center rounded-[30px] gap-4",
    },
    {
      Header: "Gestion du Stress et de l'Anxiété",
      paragraph:
        "Acquérez des techniques de gestion du stress et de l'anxiété pour rester calme et concentré pendant les périodes d'étude et les examens, favorisant ainsi un environnement propice à l'apprentissage et à la performance académique.",
      styles:
        "box mb-4 lg:mb-0 bg-blue-400 flex lg:p-8 px-5 py-8 items-center justify-center rounded-[30px] gap-4",
    },
  ];

  return (
    <>
      <div className="lg:w-3/4 2xl:w-2/4 lg:mx-auto py-20 px-5">
        <div className="h-auto lg:w-2/3 mx-auto text-center justify-center pb-20">
          <h2 className="text-lg lg:text-4xl font-bold mt-4 text-[#3D67FF]">
            Comment comprendre <br /> facilement les mathématiques?
          </h2>
          <p className="text-lg mt-2">
            Guide sur la productivité et la concentration
          </p>
        </div>

        {/* Grid layout section */}
        <div className="lg:grid lg:grid-cols-2 lg:grid-row-2 gap-5">
          {Gridlayout.map((card, index) => (
            <div key={index} className={card.styles}>
              <div className="">
                <h1 className="text-xl text-white font-extrabold mb-4">
                  {card.Header}
                </h1>
                <p className=" text-[#EFF2FB]"> {card.paragraph} </p>
              </div>
            </div>
          ))}
        </div>
        <div className="overflow-hidden">
          <div className=" absolute right-0 -translate-y-10 max-md:hidden z-10 justify-end flex">
            <img
              className="object-cover w-[80%]"
              src="./assets/image/school-iterms.png"
              alt="illustration"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GridLayout;
