import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Section1Content = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline();
    const boxs = document.querySelectorAll(".box");
    const cards = document.querySelectorAll(".card");

    cards.forEach((card) => {
      gsap.fromTo(
        card,
        {
          autoAlpha: 0,
          x: -100,
        },
        {
          duration: 0.6,
          autoAlpha: 1,
          x: 0,
          scrollTrigger: {
            trigger: card,
            start: "top 80%",
            end: "bottom 80%",
            scrub: 0.5,
          },
        }
      );
    });

    boxs.forEach((box, index) => {
      tl.fromTo(
        box,
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          duration: 0.6,
          autoAlpha: 1,
          y: 0,
          stagger: 0.1,
          scrollTrigger: {
            trigger: box,
            start: "top bottom",
            end: "bottom bottom",
            scrub: 0.5,
          },
        }
      );
    });
  }, []);
  return (
    <div className=" py-20 px-5 lg:h-[100vh] flex justify-center items-center">
      <div className=" text-center lg:text-left lg:grid lg:grid-cols-4 lg:grid-flow-row gap-8 lg:w-3/4 2xl:w-2/4 lg:mx-auto lg:px-0 lg:pt-0 justify-center items-center ">
        <div className="flex justify-center w-full mb-10 lg:justify-start lg:col-span-2 box">
          <img
            className="object-cover w-[400px] rounded-xl"
            src="./assets/image/right.png"
            alt="education"
          />
        </div>

        <div className="lg:col-span-2 h-auto flex flex-col items-center justify-center mb-4 card">
          <div className="">
            <h1 className="text-indigo-400 font-extrabold text-lg text-justify lg:text-left lg:text-4xl mb-4 ">
              Une approche unique pour apprendre et partager des idées sur notre
              plateforme
            </h1>
            <p className="leading-7 text-left text-lg">
              Découvrez une nouvelle façon d'apprendre et de collaborer avec
              d'autres étudiants sur notre plateforme. Rejoignez-nous dès
              aujourd'hui et libérez la puissance du savoir partagé.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1Content;
