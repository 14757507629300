import React from 'react'

const Loading = () => {
  return (
    <div className='bg-gray-100 text-white flex flex-col justify-center items-center h-[100vh]'>
<div class="spinner">
<div></div>
<div></div>
<div></div>
<div></div>
<div></div>
<div></div>
</div>
<img className='w-[150px] mt-8' src="./assets/image/logo_wordmark.png" alt="logo" />
    </div>
  )
}

export default Loading