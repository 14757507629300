// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import HomePage from './pages/HomePage/HomePage';
import MathPage from './pages/MathPage/MathPage';
import YoutubePage from './pages/YoutubePage/YoutubePage';
import AboutusPage from './pages/AboutusPage/AboutusPage';
import DiscordPage from './pages/DiscordPage/DiscordPage';
import ScrollToTopButton from './components/common/returnToTop';
import Loading from './components/common/Loading'; // Import Loading component
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './App.css';

const App = () => {
  const [isLoading, setIsLoading] = useState(true); // State to manage loading

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.getAll().forEach(t => t.kill()); // Kill any existing ScrollTrigger instances
    ScrollTrigger.refresh(); // Refresh ScrollTrigger to reset its state

    const tl = gsap.timeline({
      onComplete: () => setTimeout(() => {
        setIsLoading(false)
      }, 5000)  // Set loading state to false when animations are complete
    });

    tl.to('.page-content', { opacity: 1, duration: 0.5, stagger: 0.1 });
  }, []);

  return (
    <Router>
      <div className="App">
        {isLoading ? (
          <Loading /> // Show loading component while loading
        ) : (
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/maths" element={<MathPage />} />
              <Route path="/youtube" element={<YoutubePage />} />
              <Route path="/a-propos" element={<AboutusPage />} />
              <Route path="/discord" element={<DiscordPage />} />
            </Routes>
            <Footer />
            <ScrollToTopButton />
          </>
        )}
      </div>
    </Router>
  );
};

export default App;
