import React from 'react'
import DiscordBanner from '../../components/pagesContents/DiscordPageContent/DiscordBanner'
import Section1Content from "../../components/pagesContents/DiscordPageContent/Section1Content";
import Section2Content from "../../components/pagesContents/DiscordPageContent/Section2Content";
import Section3Content from "../../components/pagesContents/DiscordPageContent/Section3Content";
import Faq from "../../components/pagesContents/DiscordPageContent/Faq";
<meta name="Rejoignez notre Communauté Discord - Polytech School" content="Rejoignez notre serveur Discord pour vous connecter avec d'autres passionnés de mathématiques à Polytech School. Partagez des idées, posez des questions et participez à des discussions stimulantes sur tous les aspects des mathématiques et de l'apprentissage." />

const DiscordPage = () => {
  return (
    <>
    <section className="DiscordBanner py-2 px-5  flex flex-col items-center justify-center h-[100vh] ">
    {/* start of banner */}
    <DiscordBanner />
    {/* end of banner */}
  </section>

    {/* start of section */}
    <Section1Content />
    {/* end of section */}

    {/* start of section */}
    <Section2Content />
    {/* end of section */}

    {/* start of section */}
    <Section3Content />
    {/* end of section */}

    {/* start of section */}
    <Faq />
    {/* end of section */}
    </>
  )
}

export default DiscordPage