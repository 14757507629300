import React from "react";
import Accordion from "../../common/Accordion";

const items = [
  {
    title: "Comment fonctionnent les groupes d'étude ?",
    content:
      "Les groupes d'étude sont un environnement d'apprentissage collaboratif où les étudiants peuvent se réunir pour discuter de sujets, partager des ressources et soutenir le parcours académique les uns des autres. Notre plateforme fournit un espace dédié sur Discord pour les groupes d'étude, permettant aux étudiants de se connecter, d'organiser des sessions d'étude et d'échanger des idées.",
  },
  {
    title: "Comment puis-je rejoindre un groupe d'étude ?",
    content:
      "Pour rejoindre un groupe d'étude, il suffit de parcourir les groupes disponibles sur notre site web et d'en trouver un qui correspond à vos intérêts et besoins académiques. Une fois que vous avez trouvé un groupe adapté, vous pouvez demander à rejoindre et vous connecter avec d'autres étudiants qui étudient des sujets similaires.",
  },
  {
    title: "Comment puis-je créer un groupe d'étude ?",
    content:
      "Créer un groupe d'étude est facile ! Il vous suffit de vous inscrire sur notre site web, de naviguer jusqu'à la section des groupes d'étude et de cliquer sur le bouton \"Créer un groupe\". Remplissez les détails nécessaires tels que le sujet du groupe, les objectifs et l'horaire des réunions. Une fois votre groupe créé, vous pouvez inviter d'autres étudiants à rejoindre et à commencer à étudier ensemble.",
  },
  {
    title: "Discord est-il gratuit ?",
    content:
      "Oui, Discord est gratuit. Il fournit une plateforme de communication et de collaboration, permettant aux étudiants de se connecter les uns aux autres via des canaux vocaux, vidéo et texte. Cependant, certaines fonctionnalités premium peuvent nécessiter un abonnement.",
  },
  {
    title: "Comment puis-je vous contacter ?",
    content:
      "Si vous avez d'autres questions ou avez besoin d'assistance, n'hésitez pas à nous contacter. Vous pouvez nous contacter via le formulaire sur notre site web ou nous envoyer un e-mail. Nous serons heureux de vous aider !",
  },
];

const Faq = () => {
  return (
    <div className="lg:w-3/4 2xl:w-2/4 lg:mx-auto py-20 px-5 ">
      <div className="h-auto lg:w-1/2 pb-10 lg:pb-20 text-center mx-auto">
        <h1 className="text-[15px] font-semibold text-[#1A6340] ">Question</h1>
        <h2 className="text-lg lg:text-4xl font-bold mt-4 text-[#3D67FF]">
          FAQ
        </h2>
        <p></p>
      </div>

      <Accordion items={items} />
    </div>
  );
};

export default Faq;
