import React from "react";
import Button from "../../common/Button";

const Section3Content = () => {
  return (
    <div className=" py-20 px-5 lg:h-[70vh] overflow-hidden flex justify-center items-center -z-20">
      <div className="lg:w-3/4 lg:grid lg:grid-cols-2 2xl:w-2/4 lg:mx-auto justify-center items-start  relative">
        <div className="h-auto text-left col-span-1 card 2xl:w-2/3">
          <h2 className="text-lg lg:text-lg 2xl:text-2xl mb-4 text-justify lg:text-left ">
            En vous abonnant à ma chaîne YouTube, découvrez une approche
            dynamique des mathématiques qui favorise une progression
            enrichissante. Rejoignez la communauté des passionnés et explorez
            les mathématiques sous un nouvel angle avec plaisir!
          </h2>

          <div className="mt-10 max-sm:hidden">
            <Button
              // eslint-disable-next-line
              label={"Abonnéz-Vous"}
              customLink={
                "https://youtube.com/@PolytechSchool?si=Btg5-OzG5AcQQkL_"
              }
              customStyle={
                // eslint-disable-next-line
                "w-full lg:w-fit text-sm bg-indigo-600 text-white font-bold px-5 py-2 rounded-[30px] "
              }
            />
          </div>
        </div>

        {/* Grid layout section */}
        <div className="flex justify-center col-span-1 flex-col mt-10 lg:mt-0 lg:px-10 rounded-xl box">
          <video
            controls
            className="w-full  rounded-xl"
            src="./assets/video/video1.mp4"
          ></video>

          <div className="mt-10">
            <Button
              // eslint-disable-next-line
              label={"Abonnéz-Vous"}
              customLink={
                "https://youtube.com/@PolytechSchool?si=Btg5-OzG5AcQQkL_"
              }
              customStyle={
                // eslint-disable-next-line
                "w-full lg:hidden text-md bg-indigo-600 text-white font-bold px-5 py-2 rounded-[30px] "
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3Content;
