import React, { useState } from 'react';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderedItems = items.map((item, index) => {
    const isActive = index === activeIndex;

    return (
      <div className='p-4 mb-8 box rounded-lg h-fit border-2 cursor-pointer border-blue-300 gap-4' onClick={() => onItemClick(index)} key={index}>
        <div className='flex justify-between'>
          <h3 className='text-lg text-indigo-400 font-bold'>{item.title}</h3>
          <span>
            <img src="./assets/image/plus.svg" alt="" srcset="" />
          </span>
        </div>
        <div  className={isActive ? 'text-lg mt-4' : 'hidden'} >
        {isActive && <div>{item.content}</div>}
        </div>
      </div>
    );
  });

  return <div>{renderedItems}</div>;
};

export default Accordion;
