import React from 'react'
import YoutubeBanner from '../../components/pagesContents/YoutubePageContent/YoutubeBanner'
import Section1Content from "../../components/pagesContents/YoutubePageContent/Section1Content";
import Section2Content from "../../components/pagesContents/YoutubePageContent/Section2Content";
import Section3Content from "../../components/pagesContents/YoutubePageContent/Section3Content";
<meta name="Abonnez-vous à notre Chaîne YouTube - Polytech School" content="Abonnez-vous à notre chaîne YouTube pour accéder à des vidéos éducatives, des tutoriels, des conférences et plus encore sur les mathématiques et l'éducation à Polytech School. Restez informé, apprenez de nouveaux concepts et rejoignez notre communauté en ligne dynamique" />

const DiscordPage = () => {
  return (
    <>

    <YoutubeBanner />

    {/* start of section */}
    <Section1Content />
    {/* end of section */}

    {/* start of section */}
    <Section2Content />
    {/* end of section */}

    {/* start of section */}
    <Section3Content />
    {/* end of section */}
    </>
  )
}

export default DiscordPage