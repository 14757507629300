import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Section1Content = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline();
    const boxs = document.querySelectorAll(".box");
    const cards = document.querySelectorAll(".card");

    cards.forEach((card) => {
      gsap.fromTo(
        card,
        {
          autoAlpha: 0,
          x: -100,
        },
        {
          duration: 0.6,
          autoAlpha: 1,
          x: 0,
          scrollTrigger: {
            trigger: card,
            start: "top 80%",
            end: "bottom 80%",
            scrub: 0.5,
          },
        }
      );
    });

    boxs.forEach((box, index) => {
      tl.fromTo(
        box,
        {
          autoAlpha: 0,
          y: 100,
        },
        {
          duration: 0.6,
          autoAlpha: 1,
          y: 0,
          stagger: 0.2,
          scrollTrigger: {
            trigger: box,
            start: "top 80%",
            end: "bottom 80%",
            scrub: 0.8,
          },
        }
      );
    });
  }, []);

  return (
    <>
      <div className="text-center lg:text-left lg:grid lg:grid-cols-4 lg:grid-flow-row gap-8 lg:w-3/4 2xl:w-2/4 lg:mx-auto py-10 lg:py-0 px-5 lg:px-0 lg:pt-0 justify-center items-center lg:h-[100vh] ">
        <div className="lg:col-span-2 h-auto flex flex-col items-center justify-center mb-4 card">
          <div className="">
            <h1 className="text-indigo-400 font-extrabold text-xl text-left lg:text-4xl mb-4 ">
              je suis !
            </h1>
            <p className="leading-7 text-justify lg:text-left text-lg">
              Actuellement inscrit en master en ingénierie physique à l'École
              Polytechnique,
              <span className=" px-2 bg-green-200 font-bold">
                je suis Dybril Nahoussi.
              </span>
              Mon parcours a commencé avec une passion profonde pour la
              compréhension du monde à travers la physique, utilisant les
              mathématiques comme un langage extrêmement complet pour
              m'exprimer.
            </p>
          </div>
        </div>

        <div className="flex justify-center w-full lg:justify-end py-10 lg:col-span-2 box">
          <img
            className="object-cover w-[350px] rounded-xl"
            src="./assets/image/profil.jpg"
            alt="education"
          />
        </div>
      </div>

      {/* second section */}

      <div className=" bg-indigo-300 lg:h-[100vh]">
        <div className="clipath bg-indigo-500 lg:h-[100vh] h-[80vh] items-center flex lg:py-20">
          <div className=" text-center lg:text-left lg:grid lg:grid-cols-4 lg:grid-flow-row gap-8 lg:w-3/4 2xl:w-2/4 lg:mx-auto pb-10 px-5 lg:px-0 lg:pt-0 justify-center items-center ">
            <div className="flex lg:col-span-2 justify-center w-full py-10 max-sm:hidden relative items-center">
              <div className="bg-state-100 circle rounded-full w-3/4 h-full absolute shadow-2xl shadow-green-200"></div>
              <div className="bg-white circle rounded-full w-3/4 h-full absolute shadow-2xl shadow-blue-200"></div>
              <img
                className="illus object-cover rounded-2xl z-10 h-[300px]"
                src="./assets/image/hist.png"
                alt="education"
              />
            </div>

            <div className=" lg:col-span-2 h-auto flex flex-col items-center justify-center mb-4 card">
              <div className="">
                <h1 className="text-white font-extrabold text-xl text-left lg:text-4xl mb-4 ">
                  Ma Passion !
                </h1>
                <p className="leading-7 text-justify lg:text-left text-lg text-white">
                  Depuis mon enfance, j'ai nourri{" "}
                  <span className=" px-2 bg-red-200 font-bold text-black">
                    une profonde affinité pour les mathématiques
                  </span>
                  , rêvant de devenir scientifique dès l'âge de 7 ans. Mon
                  plaisir résidait dans l'assimilation de concepts à l'école
                  pour ensuite les partager avec mes proches, créant ainsi une
                  expérience ludique. Mes moments étaient principalement
                  consacrés à visionner des documentaires scientifiques sur des
                  chaînes telles que National Geographic,{" "}
                  <span className=" px-2 bg-red-200 font-bold text-black">
                    ce qui a cultivé en moi une passion démesurée, une curiosité
                    insatiable et une soif constante de connaissances.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* third section */}
      <div className="text-center lg:text-left lg:grid lg:grid-cols-4 lg:grid-flow-row gap-8 lg:w-3/4 2xl:w-2/4 lg:mx-auto py-10 lg:py-0 px-5 lg:px-0 lg:pt-0 justify-center items-center lg:h-[100vh] ">
        <div className="lg:col-span-2 h-auto flex flex-col items-center justify-center mb-4 card">
          <div className="">
            <h1 className="text-indigo-500 font-extrabold text-xl text-left lg:text-4xl mb-4 ">
              Mon Objectif !
            </h1>
            <p className="leading-7 text-justify lg:text-left text-lg">
              En dépit de mon statut d'étudiant modeste, la majeure partie de
              mon temps était consacrée à développer ma capacité à
              <span className="px-2 bg-yellow-200 font-bold text-black">
                transmettre le savoir de manière simple, à vulgariser les
                concepts, à déconstruire les croyances limitantes et à
                construire des méthodologies de calcul et de réflexion.
              </span>{" "}
              Étonnamment, le métier de "Professeur" ne m'a jamais vraiment
              intéressé, mais l'envie de transmettre en moi était évidente.
            </p>
            <br />
          </div>
        </div>

        <div className="flex justify-center w-full lg:justify-end py-10 lg:col-span-2 box">
          <div className="flex justify-center lg:justify-end w-full py-10 max-sm:hidden relative items-center">
            <div className="bg-state-100 circle rounded-full w-[80%] h-[80%] absolute shadow-2xl shadow-green-200"></div>
            <div className="bg-white circle rounded-full w-[80%] h-[80%] absolute shadow-2xl shadow-blue-200"></div>
            <img
              className="illus object-cover rounded-2xl w-[400px] z-10"
              src="./assets/image/Group.png"
              alt="education"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Section1Content;
